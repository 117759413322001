import React from "react";
import { useViewport } from "pages/utils/helpers";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules"; // Lazy removed

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation"; // Only import available modules
import "./Carousel.scss";
import image1 from "assets/images/grupo_nama.jpg";
import image2 from "assets/images/casa_mila.jpg";
import image3 from "assets/images/grupo_vog.png";
import image4 from "assets/images/pyco_medical.jpeg";
import image5 from "assets/images/v&l_pharma.jpeg";
import image6 from "assets/images/aic.jpg";
import image7 from "assets/images/tenera.jpg";

const Carousel = () => {
  const viewport = useViewport();
  const baseItems = [
    {
      text: 'El financiamiento Ave impulsó nuestro negocio y nos permitió abrir una nueva sucursal. El proceso fue ágil, transparente y sin largos trámites.',
      name: "Grupo Nama",
      industry: "Hospitalidad",
      image: image1,
    },
    {
      text: "A través del Crédito Ave, obtuvimos la liquidez que necesitabamos para abrir nuestro segundo estudio. Fue un proceso sencillo sin ningún tipo de burocracia.",
      name: "Casa Mila Barre",
      industry: "Centro de salud",
      image: image2,
    },
    {
      text: "El crédito Ave potenció el crecimiento de nuestra empresa y nos dió acceso a la liquidéz que necesitabamos para continuar desarrollando nuestras lineas de negocio.",
      name: "Grupo Vog",
      industry: "Comercializadora",
      image: image3,
    },
    {
      text: "El factoraje otorgado por Ave es crucial para el impulso de nuestro negocio y su constante crecimiento. La rapidez y flexibilidad del proceso nos permitió tener un acceso a liquidez inmediato, evitando trámites burocráticos innecesarios.",
      name: "PyCO medical",
      industry: "Logística y distribución",
      image: image4,
    },
    {
      text: "Gracias al financiamiento de Avefin, hemos asegurado el capital necesario para la compra de medicamentos vitales. Este apoyo nos ha permitido abastecer a los principales Institutos en México con los tratamientos indispensables para la diabetes.",
      name: "V&L Pharma",
      industry: "Logística y distribución",
      image: image5,
    },
    {
      text: "En solo 3 días, obtuvimos el financiamiento que nuestro negocio necesitaba para continuar su expansión y el desarrollo de nuevos proyectos fuera de la Ciudad de México.",
      name: "AIC",
      industry: "Manufactura",
      image: image6,
    },
    {
      text: "Gracias al capital que obtuvimos a través del Crédito Ave, tuvimos la capacidad de completar uno de los proyectos más importantes de nuestro negocio a la fecha. Todo el proceso fue transparente y digital, logrando que confiaramos en Avefin desde que cotizamos el crédito.",
      name: "Tenera",
      industry: "Manufactura",
      image: image7,
    },
  ];

  const viewportConfig = {
    xs: { slidesPerView: 1, slidesPerGroup: 1 },
    sm: { slidesPerView: 1, slidesPerGroup: 1 },
    md: { slidesPerView: 2, slidesPerGroup: 2 },
    lg: { slidesPerView: 2, slidesPerGroup: 2 },
    xl: { slidesPerView: 3, slidesPerGroup: 3 },
    "2xl": { slidesPerView: 4, slidesPerGroup: 4 },
  };

  const { slidesPerView, slidesPerGroup } = viewportConfig[viewport] || { slidesPerView: 1, slidesPerGroup: 1 };

  const items = [
    ...baseItems,
    ...baseItems.slice(0, (slidesPerGroup - (baseItems.length % slidesPerGroup)) % slidesPerGroup),
  ];

  return (
    <div className="mt-[85px]">
      <div className="carouselHeader mb-10">
        <strong>Casos de éxito Ave</strong>
      </div>
      <div className="centered-swiper" id="heroSwiperMobile">
        <Swiper
          navigation={true}
          slidesPerView={slidesPerView}
          slidesPerGroup={slidesPerGroup}
          spaceBetween={20}
          modules={[Autoplay, Navigation]} // Lazy removed from modules
          className={"heroSwiperMobile"}
          loop
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
        >
          {items.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="w-[450px] h-auto pl-[80px] ml-8">
                <div className="bgContainer w-[300px] h-[280px] rounded-2xl p-5">
                  <div className="w-full h-5/6">
                    <p className="carouselText">{item.text}</p>
                  </div>
                </div>
                <div className="flex flex-row w-full h-1/6 justify-around -ml-[70px] -mt-[60px]">
                  <div className="w-32 h-32 border-2 border-sky-500 rounded-full p-1 bg-white overflow-hidden flex items-center justify-center">
                    <img
                      src={item.image}
                      width="130"
                      className="rounded-full"
                      alt={item.name}
                    />
                  </div>
                  <div className="flex flex-col -ml-1 text-sm text-left">
                    <div className="carouselName">{item.name}</div>
                    <div className="carouselIndustry">{item.industry}</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Carousel;
