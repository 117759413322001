import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Radio from "components/Radio/Radio";
import AveRange from "components/AveRange/AveRange";
import PriceBGImage from "assets/images/estrellas.png";
import AVELogo from "assets/images/AVE_logo_white.svg";
import "./PricingSection.scss";

const PriceTypes = [
  {
    id: 0,
    label: "Factoraje",
    name: "Factoraje",
    selected: false,
    info: "Fuente de financiamento a corto plazo que proporciona liquidez inmediata a los negocios a cambio de suscuentas por corbrr.",
  },
  {
    id: 1,
    label: "Back to Back",
    name: "Back to Back",
    selected: true,
    info: "Linea de crédito flexible respaldada por inversiones que otorga liquidez inmediata sin la necesidad de vender los activos y perder sus rendimientos futuros.",
  },
  {
    id: 2,
    label: "Simple",
    name: "Simple",
    selected: false,
    info: "Producto de financiamiento que otorga la liquidez necesaria para que los negocios alcancen susmetas a mediano-largo plazo.",
  },
];

const termOptions = [
  { id: 0, label: "30 días", pros: 1.81, labelPagos: "1 pago mensual" },
  { id: 1, label: "60 días", pros: 1.86, labelPagos: "2 pagos mensuales" },
  { id: 2, label: "90 días", pros: 1.93, labelPagos: "3 pagos mensuales" },
];

const termOptions_1 = [
  { id: 0, label: "6 meses", pros: 17.25, labelPagos: "6 pagos mensuales" },
  { id: 1, label: "9 meses", pros: 17.86, labelPagos: "9 pagos mensuales" },
  { id: 2, label: "12 meses", pros: 18.49, labelPagos: "12 pagos mensuales" },
];

const termOptions_2 = [
  { id: 0, label: "6 meses", pros: 21.5, labelPagos: "6 pagos mensuales" },
  { id: 1, label: "12 meses", pros: 22.26, labelPagos: "12 pagos mensuales" },
  { id: 2, label: "18 meses", pros: 23.05, labelPagos: "18 pagos mensuales" },
  { id: 3, label: "24 meses", pros: 23.86, labelPagos: "24 pagos mensuales" },
];

const PricingSection = () => {
  const [selectedType, setSelectedType] = useState(PriceTypes[1]);
  const [price, setPrice] = useState([100000]);
  const [tasa, setTasa] = useState(0);
  const [selectedTerm, setSelectedTerm] = useState(termOptions[0].id);
  const navigate = useNavigate();

  const onChangeType = (type) => {
    setSelectedType(type);
  };

  const onSelectTerm = (termId) => {
    let pros = 0;
    if (selectedType.label === "Factoraje") {
      pros = termOptions[termId].pros;
    } else if (selectedType.label === "Back to Back") {
      pros = termOptions_1[termId].pros;
    } else {
      pros = termOptions_2[termId].pros;
    }
    setSelectedTerm(termId);
    setTasa(pros);
  };

  const plazo = {
    Factoraje: termOptions,
    "Back to Back": termOptions_1,
    Simple: termOptions_2,
  };

  useEffect(() => {
    const currentPlazo = plazo[selectedType.label]; // Get the current options for the selected type
  
    if (!currentPlazo || currentPlazo.length === 0) {
      console.warn(`No terms available for selected type: ${selectedType.label}`);
      setSelectedTerm(0);
      setTasa(0); // Default to 0 when no valid terms exist
      return;
    }
  
    if (selectedTerm >= currentPlazo.length) {
      // Reset selectedTerm to 0 if it's out of range for the new options
      console.warn(
        `Selected term index (${selectedTerm}) is out of range for ${selectedType.label}. Resetting to 0.`
      );
      setSelectedTerm(0);
      setTasa(currentPlazo[0].pros); // Set tasa based on the first option
    } else {
      // Update tasa based on the valid selectedTerm
      setTasa(currentPlazo[selectedTerm].pros);
    }
  }, [selectedType, selectedTerm]);
  
  


  
  const calculateMonthlyPayment = (price, selectedTerm, selectedType, annualRate) => {
    let payment = 0.0;

    if (selectedType.label === "Factoraje") {
      let dailyRate = annualRate / 10 / 365; // Daily rate
      payment = price * dailyRate * 30; // Factoraje payment
    } else {
      payment = ((annualRate / 100) * price) / 12; // Other loan types payment
    }

    return payment;
  };

  return (
    <div className="relative flex justify-center">
      <div className="absolute w-full h-auto inset-0 z-10">
        <img
          src={PriceBGImage}
          alt=""
          className="w-full h-full object-cover object-top"
        />
      </div>
      <div className="px-[40px] md:px-[40px] xl:px-[0px] w-full xl:w-[1050px] z-[11] mt-[50px] sm:mt-[70px] mb-[150px]">
        <div className="flex gap-[10px] sm:gap-[30px]">
          <div className="financialText">Financiamento</div>
          <div className="xl:mt-[-20px]">
            <img
              src={AVELogo}
              alt=""
              className="w-[85px] h-[32px] xl:w-[160px] xl:h-[60px] object-cover"
            />
          </div>
        </div>
        <div className="quoteText mt-[10px] sm:mt-[20px]">
          Cotiza y obtén respuesta en menos de 72 horas
        </div>
        <div className="startTopbar mt-[30px]">Simula tu financiamiento</div>
        <div className="startMain w-full xl:flex">
          <div className="w-full xl:w-[67%] p-[15px] sm:p-[30px]">
            <div className="howMuch">
              ¿Qué tipo de financiamiento necesitas?
            </div>
            <div className="w-[full] flex justify-center sm:justify-start sm:w-full mt-[25px]">
              <Radio
                data={PriceTypes}
                selected={selectedType}
                onChange={(item) => onChangeType(item)}
                gap={50}
              />
            </div>
            <div className="howMuch mt-[25px]">
              ¿Cuánto dinero necesita tu negocio?
            </div>

            <div className="mt-[30px]">
              <div className="w-full flex xl:hidden justify-center mt-[20px] sm:mt-[0px]">
                <div className="priceArea">${price.toLocaleString()}</div>
              </div>
              <div className="w-full pl-[10px] pr-[10px]">
                <AveRange
                  step={25000}
                  min={100000}
                  max={5000000}
                  value={price}
                  onChange={(value) => {
                    setPrice(value);
                  }}
                />
              </div>
              <div className="w-full flex justify-between">
                <div>$100,000</div>
                <div>$5,000,000</div>
              </div>
              <div className="w-full hidden xl:flex justify-center">
                <div className="priceArea">${price.toLocaleString()}</div>
              </div>
            </div>

            <div className="howMuch mt-[30px]">
              <div>¿A qué plazo?</div>
              <div className="w-full flex mt-[25px] gap-[5px] sm:gap-[20px]">
                {plazo[selectedType.label].map((term) => (
                  <div
                    key={term.id}
                    className="containerBtn"
                    onClick={() => onSelectTerm(term.id)}
                  >
                    <div
                      className={`${selectedTerm === term.id ? "" : "buttonPrice"} m-auto`}
                    >
                      {term.label}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="w-full xl:w-[33%] h-auto priceRightBG">
            <div className="pt-[40px] xl:pt-[50px]">
              <div className="priceRightTitle">Te prestamos hasta</div>
              <div className="priceRightValue">${price.toLocaleString()}</div>
            </div>
            <div className="mt-[30px]">
              <div className="priceRightTitle">A una tasa anual desde</div>
              <div className="priceRightValue">{tasa}%</div>
            </div>
            <div className="mt-[30px]">
              <p className="priceRightTitle">    Y {plazo[selectedType.label]?.[selectedTerm]?.labelPagos || "N/A"} de</p>
              <p className="priceRightValue">
                ${plazo[selectedType.label] && plazo[selectedType.label][selectedTerm]
                  ? calculateMonthlyPayment(price, selectedTerm, selectedType, tasa)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "0.00"}
              </p>
            </div>
            <div className="w-full flex justify-center mt-[40px] pb-[40px]">
              <div
                onClick={() => navigate("/signin")}
                className="creditBtn cursor-pointer"
              >
                Solicita tu crédito
              </div>
            </div>
          </div>
        </div>
        <div className="priceDesc w-full text-white mt-[10px] text-left">
          El simulador tiene fines demostrativos. El monto y el precio del
          crédito también estarán sujetos a otros factores tales como la
          situación financiera del negocio y su historial créditício. Los pagos
          mensuales solo consideran intereses.
        </div>
      </div>
    </div>
  );
};

export default PricingSection;
